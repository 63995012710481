import React, { useState, useRef } from 'react';
import { 
  TextField, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Button, 
  Typography, 
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormHelperText,
  IconButton,
  Tabs,
  Tab,
  Container,
  Grid,
  InputAdornment,
} from '@mui/material';

import { type } from '@testing-library/user-event/dist/type';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import GetAppIcon from '@mui/icons-material/GetApp';
import CalculateIcon from '@mui/icons-material/Calculate';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import Divider from '@mui/material/Divider';

const MonoblockPDCreator = () => {

  const [openCreateOfferDialog, setOpenCreateOfferDialog] = useState(false);
  const [offerCircularProgressVisible, setOfferCircularProgressVisible] = useState(false);
  const [message, setMessage] = useState('');

const [summaryText, setSummaryText] = useState('');
  const { t, i18n } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);

  const [numberOfSets, setNumberOfSets] = useState(1);
  const [channelWidth, setChannelWidth] = useState(0);
  const [channelDiameter, setChannelDiameter] = useState(0);
  const [channelColor, setChannelColor] = useState('');
  
  const [drainageType, setDrainageType] = useState('');
  const [channelLength, setChannelLength] = useState(0);
  const [drainageCount, setDrainageCount] = useState(0);
  const [revisionCount, setRevisionCount] = useState(0);
  const [bendCount, setBendCount] = useState('');
  const [isSealed, setIsSealed] = useState('');

  const [channelItemNo, setChannelItemNo] = useState(null);

  const [materialList, setMaterialList] = useState([]);


  const [selectedChannel, setSelectedChannel] = useState(null);


  const channelWidthOptions = [
    { value: 'PD100V (15cm)', label: 'PD100V (15cm)', diameter: 100, width: 15 },
    { value: 'PD150V (20cm)', label: 'PD150V (20cm)', diameter: 150, width: 20 },
    { value: 'PD200V (25cm)', label: 'PD200V (25cm)', diameter: 200, width: 25 },
  ];

  const channelColorOptions = [
    { value: 'naturalny', label: 'naturalny' },
    { value: 'antracytowy', label: 'antracytowy' },
  ];

  const [drainageTypeOptions, setDrainageTypeOptions] = useState([]);

  const channels = [
    { name: 'PD100V (15cm)', color: 'naturalny', code: '10832', width: 15 },
    { name: 'PD100V (15cm)', color: 'antracytowy', code: '135000', width: 15 },
    { name: 'PD150V (20cm)', color: 'naturalny', code: '416986', width: 20 },
    { name: 'PD150V (20cm)', color: 'antracytowy', code: '135001', width: 20 },
    { name: 'PD200V (25cm)', color: 'naturalny', code: '10982', width: 25 },
    { name: 'PD200V (25cm)', color: 'antracytowy', code: '11041', width: 25 }
  ];

  const ItemType = {
    CHANNEL: 'Channel',
    REVISION_WITHOUT_OUTLET: 'Revision without outlet',
    REVISION_WITH_OUTLET: 'Revision with outlet',
    DRAINAGE_BOX: 'Drainage box',
    END_CAP: 'End cap',
    END_CAP_WITH_OUTLET: 'End cap with outlet'
  };

  const [diameter, setDiameter] = useState(0);
  const [color, setColor] = useState('');
  
  const items = [
    { name: "Koryto 1m", type: ItemType.CHANNEL, color: "naturalny", width: 15, diameter:100, code: "10832" },
    { name: "Koryto 1m", type: ItemType.CHANNEL, color: "antracytowy", width: 15, diameter:100, code: "135000" },
    { name: "Koryto 1m", type: ItemType.CHANNEL, color: "naturalny", width: 20, diameter:150, code: "416986" },
    { name: "Koryto 1m", type: ItemType.CHANNEL, color: "antracytowy", width: 20, diameter:150, code: "135001" },
    { name: "Koryto 1m", type: ItemType.CHANNEL, color: "naturalny", width: 25, diameter:200, code: "10982" },
    { name: "Koryto 1m", type: ItemType.CHANNEL, color: "antracytowy", width: 25, diameter:200, code: "11041" },

    { name: "Rewizja z wybiciem", type: ItemType.REVISION_WITHOUT_OUTLET, width: 15, diameter:100, code: "10836" },
    { name: "Rewizja z wybiciem", type: ItemType.REVISION_WITHOUT_OUTLET, width: 20, diameter:150, code: "416987" },
    { name: "Rewizja z wybiciem", type: ItemType.REVISION_WITHOUT_OUTLET, width: 25, diameter:200, code: "10985" },

    { name: "PD100V (15cm)", type: ItemType.REVISION_WITH_OUTLET, width: 15, diameter:100, code: "10835" },
    { name: "PD150V (20cm)", type: ItemType.REVISION_WITH_OUTLET, width: 20, diameter:150, code: "416995" },

    { name: "Skrzynka odpływowa", type: ItemType.DRAINAGE_BOX, category: "skrzynka DN100", width: 15, diameter:100, code: "10837" },
    { name: "Skrzynka odpływowa", type: ItemType.DRAINAGE_BOX, category: "skrzynka DN150", width: 15, diameter:150, code: "10838" },
    { name: "Skrzynka odpływowa", type: ItemType.DRAINAGE_BOX, category: "skrzynka DN150", width: 20, diameter:150, code: "416990" },
    { name: "Skrzynka odpływowa", type: ItemType.DRAINAGE_BOX, category: "skrzynka DN200", width: 25, diameter:150, code: "10987" },
    { name: "Skrzynka odpływowa", type: ItemType.DRAINAGE_BOX, category: "skrzynka DN200", width: 25, diameter:200, code: "10988" },

    { name: "Ścianka zamykająca", type: ItemType.END_CAP, width: 15, diameter:100, code: "10833" },
    { name: "Ścianka zamykająca", type: ItemType.END_CAP, width: 15, diameter:100, code: "10723" },
    { name: "Ścianka zamykająca", type: ItemType.END_CAP, width: 20, diameter:150, code: "416989" },
    { name: "Ścianka zamykająca", type: ItemType.END_CAP, width: 20, diameter:150, code: "413137" },
    { name: "Ścianka zamykająca", type: ItemType.END_CAP, width: 25, diameter:200, code: "10983" },
    { name: "Ścianka zamykająca", type: ItemType.END_CAP, width: 25, diameter:200, code: "11042" },

    { name: "PD100V (15cm)", type: ItemType.END_CAP_WITH_OUTLET, width: 15, diameter:100, code: "10834" },
    { name: "PD100V (15cm)", type: ItemType.END_CAP_WITH_OUTLET, width: 15, diameter:100, code: "10724" },
    { name: "PD150V (20cm)", type: ItemType.END_CAP_WITH_OUTLET, width: 20, diameter:150, code: "416988" },
    { name: "PD150V (20cm)", type: ItemType.END_CAP_WITH_OUTLET, width: 20, diameter:150, code: "413138" },
    { name: "PD200V (25cm)", type: ItemType.END_CAP_WITH_OUTLET, width: 25, diameter:200, code: "10984" },
    { name: "PD200V (25cm)", type: ItemType.END_CAP_WITH_OUTLET, width: 25, diameter:200, code: "11043" },
  ];

  const [runoffCoefficient, setRunoffCoefficient] = useState(0.75);
  const [rainfallIntensity, setRainfallIntensity] = useState('bogdanowicz-stachy');
  const [rainfallRegion, setRainfallRegion] = useState('poznan-szczecin-gdansk');
  const [rainfallProbability, setRainfallProbability] = useState(2);
  const [channelFillPercentage, setChannelFillPercentage] = useState(80);

  // Add new state variables for hydraulic calculation results
  const [rainfallIntensityResult, setRainfallIntensityResult] = useState(137);
  const [channelCapacity, setChannelCapacity] = useState(6.4);
  const [drainageArea, setDrainageArea] = useState(620);

  const clearError = (field) => {
    setErrors(prev => {
      const newErrors = { ...prev };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleNumberOfSetsChange = (e) => {
    const value = Math.max(1, parseInt(e.target.value) || 1);
    setNumberOfSets(value);
    if (value >= 1) clearError('numberOfSets');
  };

  const handleChannelWidthChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = channelWidthOptions.find(option => option.value === selectedValue);
    
    if (selectedOption) {
      setSelectedChannel(event.target.value);
      setChannelWidth(selectedOption.width);
      setChannelDiameter(selectedOption.diameter);
      clearError('channelWidth');
    } else {
      setSelectedChannel(null);
      setChannelWidth('');
      setChannelDiameter('');
    }
  };

  function findItemByProperties(color, width, diameter) {
    return items.find(item => 
      item.color === color && 
      item.width === width && 
      item.diameter === diameter
    );
  }

  const handleChannelColorChange = (event) => {
    setChannelColor(event.target.value);
    clearError('channelColor');

 let x = []

switch(channelDiameter){
  case 100:
    x.push({ value: 'skrzynka DN100', label: 'skrzynka DN100', symbol: 'DN100', type: ItemType.DRAINAGE_BOX });
    break;
  case 150:
    x.push({ value: 'skrzynka DN150', label: 'skrzynka DN150', symbol: 'DN150', type: ItemType.DRAINAGE_BOX });
    break;
  case 200:
    x.push({ value: 'skrzynka DN200', label: 'skrzynka DN200', symbol: 'DN200', type: ItemType.DRAINAGE_BOX });
    break;
}

x.push({ value: 'rewizja z uszczelką', label: 'rewizja z uszczelką', symbol:'', type: ItemType.REVISION_WITH_OUTLET });
x.push({ value: 'rewizja z wybiciem', label: 'rewizja z wybiciem', symbol:'', type: ItemType.REVISION_WITHOUT_OUTLET });
x.push({ value: 'ścianka z uszczelką', label: 'ścianka z uszczelką', symbol:'', type: ItemType.END_CAP_WITH_OUTLET });

    setDrainageTypeOptions(x);
    
  };

  const handleDrainageTypeChange = (event) => {
    const selectedValue = event.target.value;
    setDrainageType(selectedValue);
    clearError('drainageType');
    

  };


  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (numberOfSets < 1) newErrors.numberOfSets = t('numberOfSetsError');
    if (!channelWidth) newErrors.channelWidth = t('channelWidthError');
    if (!channelColor) newErrors.channelColor = t('channelColorError');
    if (!drainageType) newErrors.drainageType = t('drainageTypeError');
    if (!channelLength) newErrors.channelLength = t('channelLengthError');
    // if (!drainageCount) newErrors.drainageCount = "Wpisz ilość odpływów";
    // if (!revisionCount) newErrors.revisionCount = "Wpisz ilość rewizji";
    // if (bendCount === '') newErrors.bendCount = "Wpisz ilość załamań";
    if (!isSealed) newErrors.isSealed = t('isSealedError');

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const materialListRef = useRef(null);

  const handleCalculate = () => {
    if (validateForm()) {
      //const found = channels.find(channel => 
      //  channel.name.includes(channelWidth) && channel.color === channelColor
      //);
      //setSelectedChannel(found || null);

let lp = 0;

  let materials = []

  let endCapQtyLoc = parseInt(2);
  let channelLengthLoc = parseInt(channelLength);
  let drainageCountLoc = parseInt(drainageCount);
  let revisionCountLoc = parseInt(revisionCount);
  let bendCountLoc = parseInt(bendCount);
  
  drainageCountLoc *= numberOfSets;
  revisionCountLoc *= numberOfSets;
  bendCountLoc *= numberOfSets;
  channelLengthLoc *= numberOfSets;

  if (bendCountLoc > 0) {
    endCapQtyLoc = endCapQtyLoc + bendCountLoc;
    revisionCountLoc = revisionCountLoc +bendCountLoc;
  }
    // 1. kanał monoblokowy
    console.clear();
   
    const drainageCountFloat = drainageCountLoc * 0.5;
    const revisionCountFloat = revisionCountLoc * 0.5;
    const channellQuantity = Math.ceil(channelLengthLoc - drainageCountFloat - revisionCountFloat);
    console.log(drainageCountFloat);
    const foundItem = findItemByProperties(channelColor, channelWidth, channelDiameter);
    setChannelItemNo(foundItem.code);
    lp += 1
    materials.push({ lp: lp, name: foundItem.name, code: foundItem.code, quantity: channellQuantity})

    //2. rewizja bez odpływu

const revisionFound = items.find(item => 
  item.type === ItemType.REVISION_WITHOUT_OUTLET &&
  item.diameter === channelDiameter &&
  item.width === channelWidth
)
lp += 1
materials.push({lp: lp, name: revisionFound.name, code: revisionFound.code, quantity: revisionCountLoc})


    // 3. odpływ
    const selectedDrainageType = drainageTypeOptions.find(option => option.value === drainageType);
    if (selectedDrainageType) {
      const foundDrainage = items.find(item => 
        item.type === selectedDrainageType.type &&
        item.diameter === channelDiameter &&
        item.width === channelWidth
      )
      lp += 1
      materials.push({lp: lp, name: foundDrainage.name, code: foundDrainage.code, quantity: drainageCountLoc})
    }

    //4. ścianka zamykajaca
    
    const foundEndCap = items.find(item => 
      item.type === ItemType.END_CAP &&
      item.diameter === channelDiameter &&
      item.width === channelWidth
    )
    lp += 1
    materials.push({lp: lp, name: foundEndCap.name, code: foundEndCap.code, quantity: endCapQtyLoc})

    //5. łączenia
    const jointsCount = parseInt(channelLengthLoc) + drainageCountLoc + revisionCountLoc;
    let massQty = 0;

    switch(channelDiameter) {
      case 100:
        massQty = 18;
        break;
      case 150:
        massQty = 27;
        break;
      case 200:
        massQty = 32;
        break;
    }
    let sealingCompoundQty =  Math.ceil((jointsCount * parseInt(massQty) * 1.1) / 450)
    let primerQty = Math.ceil(sealingCompoundQty / 5)

    switch(isSealed){
      case 'nie':
        break;
      case 'tak':
        lp += 1
        materials.push({lp: lp, name: 'Masa uszczelniająca', code: '10681', quantity: sealingCompoundQty})    
        lp += 1
        materials.push({lp: lp, name: 'Primer', code: '10682', quantity: primerQty})    
        break;
      case 'tak_zestaw':
        lp += 1
        materials.push({lp: lp, name: 'Masa uszczelniająca', code: '10681', quantity: sealingCompoundQty})    
        lp += 1
        materials.push({lp: lp, name: 'Primer', code: '10682', quantity: primerQty})    
        lp += 1
        materials.push({lp: lp, name: 'Zestaw roboczy', code: '01376', quantity: 1})    
        break;
    }


    generateSummaryText();
    setMaterialList(materials);

    // Scroll to material list after a short delay
    setTimeout(() => {
      materialListRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
    }
  };

  const handleSavePDF = () => {
    const doc = new jsPDF();

    doc.text(t('materialList'), 14, 15);
    
    const tableColumn = [t('no'), t('part'), t('catalogNumber'), t('quantity')];
    const tableRows = materialList.map(item => [item.lp, item.name, item.code, item.quantity]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { font: 'NotoSans' },
      headStyles: { font: 'NotoSans' },
      bodyStyles: { font: 'NotoSans' },
    });

    doc.save("material-list.pdf");
  };

  const handleCreateOffer = () => {
    setOpenCreateOfferDialog(true);
    setMessage('Creating quote...');
    setOfferCircularProgressVisible(true);

    setTimeout(() => {
      setOfferCircularProgressVisible(false);
      setMessage('Quote created successfully');
      setTimeout(() => {
        setOpenCreateOfferDialog(false);
      }, 1000);
    }, 1500);
  };

  const handleDownload = (type, item) => {
    // Implement download logic here
    console.log(`Downloading ${type} for item ${item.name}`);
  };

  const generateSummaryText = () => {
    const widthHeight = {
      100: '150/240',
      150: '200/270',
      200: '250/320'
    };

    const selectedDrainageType = drainageTypeOptions.find(option => option.value === drainageType);
    
    var drainageTypeText = "";
    switch(selectedDrainageType.type) {
      case ItemType.DRAINAGE_BOX:
        drainageTypeText =  t('drainageTypeText') + `${selectedDrainageType.symbol}`;
        break;
      case ItemType.REVISION_WITH_OUTLET:
        drainageTypeText = t('drainageTypeText2');
        break;
      case ItemType.REVISION_WITHOUT_OUTLET:
        drainageTypeText = t('drainageTypeText3');
        break;
      case ItemType.END_CAP_WITH_OUTLET:
        drainageTypeText = t('drainageTypeText4');
    }

    var t1 = t('summaryText1');
    var t2 = t('summaryText2');
    var t3 = t('summaryText3');
    var rainfall = t('rainfall');
    setSummaryText(
      `${t1} ${channelLength}m - ${drainageCount} ${drainageTypeText} ${t2} ${widthHeight[channelDiameter]}mm ${t3} 2500m2 (${rainfall} 211 l/sha)`);
  };

  const handleReset = () => {
    setNumberOfSets(1);
    setChannelWidth(0);
    setChannelDiameter(0);
    setChannelColor('');
    setDrainageType('');
    setChannelLength(0);
    setDrainageCount(0);
    setRevisionCount(0);
    setBendCount('');
    setIsSealed('');
    setErrors({});
    setMaterialList([]);
    setSummaryText('');
    setSelectedChannel(null);
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <Grid container  disableGutters>
        {/* Left column - Form */}
        <Grid item xs={12} md={4} lg={3} disableGutters>
          <Box sx={{ p: 0, borderRight: { md: '1px solid rgba(0, 0, 0, 0.12)' } }} >
            <Accordion defaultExpanded sx={{ boxShadow: 'none', '&:before': { display: 'none' }, '& .MuiAccordionSummary-root': { minHeight: 0, padding: 0 } }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ backgroundColor: '#84abc1' }}
              >
                <Typography variant="h7" sx={{ color: 'white', ml:2, fontWeight: 'bold' }}>{t('configurator')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* Form fields */}
                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  label={t('numberOfSets')}
                  type="number"
                  value={numberOfSets}
                  onChange={handleNumberOfSetsChange}
                  inputProps={{ min: 1 }}
                  error={!!errors.numberOfSets}
                  helperText={errors.numberOfSets}
                />

                <FormControl fullWidth size="small" margin="dense" error={!!errors.channelWidth}>
                  <InputLabel id="channelWidthLabel">{t('channelWidth')}</InputLabel>
                  <Select 
                  labelId="channelWidthLabel"
                  id="channelWidth"
                  value={selectedChannel} 
                  label={t('channelWidth')}
                  onChange={handleChannelWidthChange}
                  >
                    {channelWidthOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                  {errors.channelWidth && <FormHelperText>{errors.channelWidth}</FormHelperText>}
                </FormControl>

                <FormControl fullWidth size="small" margin="dense" error={!!errors.channelColor}>
                  <InputLabel id="channelColorLabel">{t('channelColor')}</InputLabel>
                  <Select 
                  labelId="channelColorLabel"
                  id="channelColor"
                  value={channelColor} 
                  label={t('channelColor')}
                  onChange={handleChannelColorChange}
                  >
                    {channelColorOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                  {errors.channelColor && <FormHelperText>{errors.channelColor}</FormHelperText>}
                </FormControl>

                <FormControl fullWidth size="small" margin="dense" error={!!errors.drainageType}>
                  <InputLabel id="drainageTypeLabel">{t('drainageType')}</InputLabel>
                  <Select 
                  labelId="drainageTypeLabel"
                  id="drainageType"
                  value={drainageType} 
                  label={t('drainageType')}
                  onChange={handleDrainageTypeChange}
                  >
                    {drainageTypeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                  {errors.drainageType && <FormHelperText>{errors.drainageType}</FormHelperText>}
                </FormControl>

                <FormControl fullWidth size="small" margin="dense" error={!!errors.channelLength}>
                  <TextField
                    id="channelLength"
                    type="number"
                    label={t('channelLength')}
                    value={channelLength}
                    onChange={(e) => setChannelLength(e.target.value)}
                    inputProps={{ min: 0 }}
                  />
                  {errors.channelLength && <FormHelperText>{errors.channelLength}</FormHelperText>}
                </FormControl>

                <FormControl fullWidth size="small" margin="dense">
                  <TextField
                    id="drainageCount"
                    label={t('drainageCount')}
                    type="number"
                    value={drainageCount}
                    onChange={(e) => setDrainageCount(e.target.value)}
                    inputProps={{ min: 0 }}
                  />
                </FormControl>

                <FormControl fullWidth size="small" margin="dense">
                  <TextField
                    id="revisionCount"
                    label={t('revisionCount')}
                    type="number"
                    value={revisionCount}
                    onChange={(e) => setRevisionCount(e.target.value)}
                    inputProps={{ min: 0 }}
                  />
                </FormControl>

                <FormControl fullWidth size="small" margin="dense">
                  <TextField
                    id="bendCount"
                    label={t('bendCount')}
                    type="number"
                    value={bendCount}
                    onChange={(e) => setBendCount(e.target.value)}
                    inputProps={{ min: 0 }}
                  />
                </FormControl>

                <FormControl fullWidth size="small" margin="dense" error={!!errors.isSealed}>
                  <InputLabel id="isSealedLabel">{t('isSealed')}</InputLabel>
                  <Select 
                  labelId="isSealedLabel"
                  id="isSealed"
                  value={isSealed} 
                  label={t('isSealed')}
                  onChange={(e) => setIsSealed(e.target.value)}
                  >
                    <MenuItem value="nie">{t('no')}</MenuItem>
                    <MenuItem value="tak">{t('yes')}</MenuItem>
                    <MenuItem value="tak_zestaw">{t('yesWithSet')}</MenuItem>
                  </Select>
                  {errors.isSealed && <FormHelperText>{errors.isSealed}</FormHelperText>}
                </FormControl>

                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={handleCalculate}
                  >
                    {t('calculate')}
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={handleReset}
                    sx={{ backgroundColor: '#84abc1', '&:hover': { backgroundColor: '#6b8a9e' } }}
                  >
                    {t('reset')}
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ boxShadow: 'none', '&:before': { display: 'none' }, '& .MuiAccordionSummary-root': { minHeight: 0, padding: 0 } }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{ backgroundColor: '#84abc1' }}
              >
                <Typography variant="h7" sx={{ color: 'white', ml:2, fontWeight: 'bold' }}>{t('hydraulicCalculation')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl fullWidth size="small" margin="dense">
                  <InputLabel>{t('runoffCoefficient')}</InputLabel>
                  <Select
                    value={runoffCoefficient}
                    label={t('runoffCoefficient')}
                    disabled
                  >
                    <MenuItem value={0.75}>{t('pavingStones')}</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth size="small" margin="dense">
                  <InputLabel>{t('rainfallIntensity')}</InputLabel>
                  <Select
                    value={rainfallIntensity}
                    label={t('rainfallIntensity')}
                    disabled
                  >
                    <MenuItem value="bogdanowicz-stachy">{t('bogdanowiczStachy')}</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth size="small" margin="dense">
                  <InputLabel>{t('rainfallRegion')}</InputLabel>
                  <Select
                    value={rainfallRegion}
                    label={t('rainfallRegion')}
                    disabled
                  >
                    <MenuItem value="poznan-szczecin-gdansk">{t('poznanSzczecinGdansk')}</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth size="small" margin="dense">
                  <InputLabel>{t('rainfallProbability')}</InputLabel>
                  <Select
                    value={rainfallProbability}
                    label={t('rainfallProbability')}
                    disabled
                  >
                    <MenuItem value={2}>{t('twoYears')}</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  label={t('channelFillPercentage')}
                  type="number"
                  value={channelFillPercentage}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    readOnly: true,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ boxShadow: 'none', '&:before': { display: 'none' }, '& .MuiAccordionSummary-root': { minHeight: 0, padding: 0 } }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                sx={{ backgroundColor: '#84abc1' }}
              >
                <Typography variant="h7" sx={{ color: 'white', ml:2, fontWeight: 'bold' }}>{t('hydraulicResults')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  label={t('rainfallIntensity')}
                  value={rainfallIntensityResult}
                  InputProps={{
                    readOnly: true,
                    endAdornment: <InputAdornment position="end">l/sha</InputAdornment>,
                  }}
                />
                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  label={t('runoffCoefficient')}
                  value={runoffCoefficient}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  label={t('channelFillPercentage')}
                  value={channelFillPercentage}
                  InputProps={{
                    readOnly: true,
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  label={t('channelCapacity')}
                  value={channelCapacity}
                  InputProps={{
                    readOnly: true,
                    endAdornment: <InputAdornment position="end">l/s</InputAdornment>,
                  }}
                  sx={{ '& .MuiInputBase-input': { backgroundColor: 'lightgreen' } }}
                />
                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  label={t('drainageArea')}
                  value={drainageArea}
                  InputProps={{
                    readOnly: true,
                    endAdornment: <InputAdornment position="end">m²</InputAdornment>,
                  }}
                  sx={{ '& .MuiInputBase-input': { backgroundColor: 'lightgreen' } }}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>

        {/* Right column - BOM Preview */}
        <Grid item xs={12} md={8} lg={9} >
          <Box sx={{ p: 2 }} >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h5" gutterBottom align="left">{t('materialList')}</Typography>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<PictureAsPdfIcon />}
                  onClick={handleSavePDF}
                  sx={{ mr: 1 }}
                  disabled={materialList.length === 0}
                >
                  {t('savePDF')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<NoteAddIcon />}
                  onClick={handleCreateOffer}
                  disabled={materialList.length === 0}
                >
                  {t('createOffer')}
                </Button>
              </Box>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2} justifyContent="space-between" sx={{ mb: 2 }}>
              <Grid item xs={12} sm={4}>
                <Box sx={{ textAlign: 'center' }}>
                  <img src="/images/rain.png" alt="Deszcz miarodajny" style={{ width: '100%', maxWidth: 50, height: 'auto' }} />
                  <Typography variant="body2">{t('rainfallIntensity')}</Typography>
                  <Typography variant="body1" fontWeight="bold">211 l/ha</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box sx={{ textAlign: 'center' }}>
                  <img src="/images/surface.png" alt="Współczynnik nawierzchni" style={{ width: '100%', maxWidth: 50, height: 'auto' }} />
                  <Typography variant="body2">{t('runoffCoefficient')}</Typography>
                  <Typography variant="body1" fontWeight="bold">asfalt - 0.9</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box sx={{ textAlign: 'center' }}>
                  <img src="/images/area.png" alt="Odwadniany obszar" style={{ width: '100%', maxWidth: 50, height: 'auto' }} />
                  <Typography variant="body2">{t('drainageArea')}</Typography>
                  <Typography variant="body1" fontWeight="bold">1125 m²</Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body2" gutterBottom align="left" sx={{ mb: 2 }}>{summaryText}</Typography>
            <Divider sx={{ my: 2 }} />
            {materialList.length > 0 && (
              <>
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('no')}</TableCell>
                        <TableCell>{t('part')}</TableCell>
                        <TableCell>{t('catalogNumber')}</TableCell>
                        <TableCell>{t('quantity')}</TableCell>
                        <TableCell align="center">{t('card')}</TableCell>
                        <TableCell align="center">{t('pdf3d')}</TableCell>
                        <TableCell align="center">{t('dwg')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {materialList.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.lp}</TableCell>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.code}</TableCell>
                          <TableCell>{item.quantity}</TableCell>
                          <TableCell align="center">
                            <IconButton size="small">
                              <GetAppIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">
                            <IconButton size="small">
                              <GetAppIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">
                            <IconButton size="small">
                              <GetAppIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                  <Button
                    variant="contained"
                    startIcon={<PictureAsPdfIcon />}
                    onClick={handleSavePDF}
                  >
                    {t('savePDF')}
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<NoteAddIcon />}
                    onClick={handleCreateOffer}
                  >
                    {t('createOffer')}
                  </Button>
                </Box> */}
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      {/* Add this Dialog component at the end of the return statement */}
      <Dialog open={openCreateOfferDialog} onClose={() => setOpenCreateOfferDialog(false)}>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 2 }}>
            {offerCircularProgressVisible ? (
              <CircularProgress sx={{ mb: 2 }} />
            ) : (
              <CheckCircleOutlineIcon color="success" sx={{ fontSize: 48, mb: 2 }} />
            )}
            <Typography>{message}</Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default MonoblockPDCreator;