import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';


const DecklinePCreator = () => {
  const { t } = useTranslation();
  
  return (
    <div>
     <Typography variant="h4" gutterBottom>{t('decklineP')}</Typography>
    </div>
  );
};

export default DecklinePCreator;