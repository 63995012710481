import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const MultilineNiskieCreator = () => {
  const { t } = useTranslation();
  return (
    <div>
    <Typography variant="h4" gutterBottom>{t('multilineNiskie')}</Typography>
    </div>
  );
};

export default MultilineNiskieCreator;