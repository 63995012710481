import React, { useState, useEffect } from 'react';
import { MsalProvider, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import MonoblockPDCreator from './components/MonoblockPDCreator';
import { AppBar, Stack, Card, Divider, Toolbar, Typography, Button, Container, Box, Menu, MenuItem, Grid2, Select, FormControl, InputLabel, Breadcrumbs } from '@mui/material';
import './App.css';
import logo from './logo.svg';
import { styled } from '@mui/system';
import MultilineBasicCreator from './components/MultilineBasicCreator';
import SKCreator from './components/SKCreator';
import MonoblockRDCreator from './components/MonoblockRDCreator';
import SportCreator from './components/SportCreator';
import GalaCreator from './components/Gala';
import MultilineNiskieCreator from './components/MultilineNiskieCreator';
import DecklinePCreator from './components/DecklinePCreator';
import MultislotCreator from './components/MultislotCreator';
import XtraLineCreator from './components/XtraLineCreator';
import ParkingCreator from './components/ParkingoweCreator';
import MarkantCreator from './components/MarkantCreator';
import RopopochodneCreator from './components/RopopochodneCreator';
import OsadnikiCreator from './components/OsadnikiCreator';
import SPINCreator from './components/SPINCreator';
import Dashboard from './components/Dashboard'
import { useTranslation } from 'react-i18next';
import './i18n';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PostAddIcon from '@mui/icons-material/PostAdd';
import TableChartIcon from '@mui/icons-material/TableChart';
import PersonIcon from '@mui/icons-material/Person';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Quotes from './components/quoteList';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MuiAppBar from '@mui/material/AppBar';

// Update the msalConfig object
const msalConfig = {
  auth: {
    // clientId: '14caccc9-fd16-49dc-82a0-7731c2a1111d',
    // authority: 'https://login.microsoftonline.com/42d32904-928a-49ab-b159-3d5fbfef7927',
    clientId: 'fc60ee67-2c88-4983-a536-ddcf91ac15f5',
    authority: 'https://login.microsoftonline.com/b10d1c10-af4c-43c3-a4bc-ee09571cd080',
    redirectUri: window.location.origin // Use dynamic origin instead of hardcoded localhost
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  }
};

// Initialize MSAL instance outside of the component
const msalInstance = new PublicClientApplication(msalConfig);

// Create a custom theme
const theme = createTheme({
  typography: {
    fontFamily: '"StoneSansIIITCW05-Medium", Geneva, Arial, sans-serif',
  },
  palette: {
    primary: {
      main: '#bf030c',
    },
    secondary: {
      main: '#84abc1',
    },
    text: {
      primary: '#000000', // Keep the primary text color as black
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          '&:hover': {
           // color: '#bf030c',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&:hover': {
            //color: '#bf030c',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#ffffff', // Set button text color to white
          '&:hover': {
            backgroundColor: '#a8020a', // Slightly darker shade for hover effect
          },
        },
      },
    },
  },
});

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    // Remove the padding line below
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <AppContent />
      </ThemeProvider>
    </MsalProvider>
  );
}

function AppContent() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  return (
    <div className="App" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {!isAuthenticated && <LoginComponent instance={instance} accounts={accounts} isAuthenticated={isAuthenticated} />}  
      {isAuthenticated && <ConfiguratorComponent instance={instance} accounts={accounts} isAuthenticated={isAuthenticated} />}  
      <Footer />
    </div>
  );
}

function LoginComponent({ instance, accounts, isAuthenticated }) {
  const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
  }));

  const [userName, setUserName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  // useEffect(() => {
  //   if (isAuthenticated && accounts.length > 0) {
  //     const account = accounts[0];
  //     const name = `${account.name || ''}`.trim();
  //     setUserName(name);
  //   } else {
  //     setUserName('');
  //   }
  // }, [isAuthenticated, accounts]);

  const handleLogin = async () => {
    try {
      await instance.loginPopup();
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  
  
  return (
    <StyledBox sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh' 
    }}>
      <Card 
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 3,
          maxWidth: 300
        }}
      >
        <img src={logo} alt="ACO" style={{ height: '40px', marginBottom: '16px' }} />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleLogin}
          sx={{ color: 'white' }}
        >
          Sign in with Azure AD
        </Button>
      </Card>
    </StyledBox>
    // <StyledBox>
    //   <Typography variant="h4" gutterBottom>
    //     Login
    //   </Typography>
    //   {/* {isAuthenticated ? (
    //     <>
    //       <Button color="inherit" onClick={handleMenuOpen}>
    //         {userName}
    //       </Button>
    //       <Menu
    //         anchorEl={anchorEl}
    //         open={Boolean(anchorEl)}
    //         onClose={handleMenuClose}
    //       >
    //         <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
    //       </Menu>
    //     </>
    //   ) : ( */}
    //     <Button
    //       variant="contained"
    //       color="primary"
    //       onClick={handleLogin}
    //       sx={{ mb: 2 }}
    //     >
    //       {t('login')}
    //     </Button>
    //   {/* )} */}
    //   <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    //     {/* <Link href="/forgot-password" underline="hover">
    //       Forgot Username / Password?
    //     </Link>
    //     <Link href="/signup" underline="hover">
    //       Don't have an account? Sign up
    //     </Link> */}
    //   </Box>
    // </StyledBox>
  );
}

function Configurators({ components, onComponentClick }) {
  return (
    <Grid container spacing={3} sx={{ p: 2 }}>
      {components.map((component, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Card>
            <CardActionArea onClick={() => onComponentClick(component.name)}>
              <CardMedia
                component="img"
                height="100"
                image={`/images/${component.img}`}
                alt={component.name}
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {component.name}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

function ConfiguratorComponent({ instance, accounts, isAuthenticated }) {
  const [activeComponent, setActiveComponent] = useState('Dashboard');
  const [anchorEl, setAnchorEl] = useState(null);
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const [expandedMenuItem, setExpandedMenuItem] = useState(null);

  const components = [
    { name: t('multilineBasic'), img: 'multiline_basic.png', component: MultilineBasicCreator },
    { name: t('sk'), img: 'sk.png', component: SKCreator },
    { name: t('monoblockPD'), img: 'monoblock_pd.png', component: MonoblockPDCreator },
    { name: t('monoblockRD'), img: 'monoblock_rd.png', component: MonoblockRDCreator },
    { name: t('sport'), img: 'sport.png', component: SportCreator },
    { name: t('gala'), img: 'gala.png', component: GalaCreator },
    { name: t('multilineNiskie'), img: 'multiline_niskie.png', component: MultilineNiskieCreator },
    { name: t('decklineP'), img: 'deckline_p.png', component: DecklinePCreator },
    { name: t('multislot'), img: 'multislot.png', component: MultislotCreator },
    { name: t('xtraLine'), img: 'xtraline.png', component: XtraLineCreator },
    { name: t('parkingowe'), img: 'parkingowe.png', component: ParkingCreator },
    { name: t('spin'), img: 'spin.png', component: SPINCreator },
    { name: t('markant'), img: 'markant.png', component: MarkantCreator },
    { name: t('ropopochodne'), img: 'ropopochodne.png', component: RopopochodneCreator },
    { name: t('osadniki'), img: 'osadniki.png', component: OsadnikiCreator },
  ];

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon /> },
    { text: 'Configurators', icon: <PostAddIcon /> },
    { text: 'Quotes', icon: <TableChartIcon />  }
  ];

  const handleComponentClick = (componentName) => {
    setActiveComponent(componentName);
  };

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const handleBackToMenu = () => {
    setActiveComponent(null);
  };

  const handleLogout = () => {
    instance.logout();
    handleMenuClose();
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMenuItemClick = (index) => {
    if (menuItems[index].subItems) {
      setExpandedMenuItem(expandedMenuItem === index ? null : index);
    } else {
      // Handle click for non-expandable items
      if (menuItems[index].text === 'Dashboard') {
        setActiveComponent('Dashboard');
      } else if (menuItems[index].text === 'Quotes') {
        setActiveComponent('Quotes');
      } else if (menuItems[index].text === 'Configurators') {
        setActiveComponent('Configurators');
      }
    }
  };

  return (
    <>
      <StyledAppBar position="fixed" open={drawerOpen}>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                edge="start"
                sx={{ mr: 2, ...(drawerOpen && { display: 'none' }) }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div" >
                Product Configurator
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControl sx={{ mr: 2 }}>
                <Select
                  labelId="language-select-label"
                  value={i18n.language}
                  onChange={changeLanguage}
                  label={t('language')}
                  sx={{ 
                    color: 'black',
                    '& .MuiSelect-icon': { color: 'black' },
                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                  }}
                >
                  <MenuItem value="en">🇬🇧</MenuItem>
                  <MenuItem value="pl">🇵🇱</MenuItem>
                  <MenuItem value="da">🇩🇰</MenuItem>
                  <MenuItem value="de">🇩🇪</MenuItem>
                </Select>
              </FormControl>
              <Button
                color="inherit"
                variant="text"
                onClick={handleMenuOpen}
                endIcon={<ArrowDropDownIcon />}
              >
                {isMobile ? '' : accounts[0]?.name || ''} 
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </StyledAppBar>
      
      <Box sx={{ display: 'flex' }}>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant={isMobile ? "temporary" : "persistent"}
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer}
        >
          <DrawerHeader sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

            <img src={logo} alt="ACO Logo" style={{ height: '40px', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }} />
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {menuItems.map((item, index) => (
              <React.Fragment key={item.text}>
                <ListItem 
                  button 
                  onClick={() => handleMenuItemClick(index)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                  {item.subItems && (expandedMenuItem === index ? <ExpandLess /> : <ExpandMore />)}
                </ListItem>
                {item.subItems && (
                  <Collapse in={expandedMenuItem === index} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.subItems.map((subItem, subIndex) => (
                        <ListItem 
                          button 
                          key={subItem.text} 
                          sx={{ pl: 4 }}
                          onClick={() => handleComponentClick(subItem.text)}
                        >
                          <ListItemText primary={subItem.text} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
        </Drawer>

        <Main open={drawerOpen}>
          <DrawerHeader />
          <Container maxWidth={false} disableGutters>
            <Breadcrumbs 
              aria-label="breadcrumb" 
              separator={<NavigateNextIcon fontSize="small" />}
              sx={{ mt: 2, mb: 2 }}
            >
               <Button
                 color="primary"
                 startIcon={<HomeIcon />}
                 onClick={() => setActiveComponent('Dashboard')}
                 sx={{ textTransform: 'none', backgroundColor: 'transparent', '&:hover': { backgroundColor: 'transparent' }, color: '#bf030c' }}
               >
                 {t('home')}
               </Button>
              {activeComponent !== 'Dashboard' && (
                <Typography color="text.primary">{activeComponent}</Typography>
              )}
            </Breadcrumbs>

            <Divider sx={{ mb: 0 }} />

            {activeComponent === 'Dashboard' && (
              <Box sx={{ mt: 0 }}>
                <Dashboard />
              </Box>
            )}
            {activeComponent === 'Configurators' && (
              <Box sx={{ mt: 0 }}>
                <Configurators components={components} onComponentClick={handleComponentClick} />
              </Box>
            )}
            {activeComponent !== 'Dashboard' && activeComponent !== 'Quotes' && activeComponent !== 'Configurators' && (
              <Box sx={{ mt: 0 }}>
                {React.createElement(
                  components.find(c => c.name === activeComponent)?.component || (() => null)
                )}
              </Box>
            )}
            {activeComponent === 'Quotes' && (
              <Box sx={{ mt: 0 }}>
                <Quotes />
              </Box>
            )}
          </Container>
        </Main>
      </Box>
    </>
  );
}

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <AppBar position="static" color="default" elevation={1} component="footer" sx={{ mt: 'auto', py: 2 }}>
      <Container maxWidth="lg">
        <Typography variant="body2" color="black" align="center">
          © {currentYear} Xtbc All rights reserved. This is a prototype developed for ACO.
        </Typography>
      </Container>
    </AppBar>
  );
}

export default App;