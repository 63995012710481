import React, { useState } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Typography,
  Box,
  Divider,
  Container
} from '@mui/material';
import { useTranslation } from 'react-i18next';
const QuoteList = () => {
  // Mock data for the offers
  const { t } = useTranslation();
  const [quotes, setQuotes] = useState([
    { id: 1, name: 'Quote 1', type: 'Multiline Basic', date: '2023-05-01', status: 'Pending' },
    { id: 2, name: 'Quote 2', type: 'Monoblock PD', date: '2023-05-02', status: 'Approved' },
    { id: 3, name: 'Quote 3', type: 'SK', date: '2023-05-03', status: 'Rejected' },
    // Add more mock data as needed
  ]);

  return (
   <>

      <Divider sx={{ width: '100%', mb: 2 }} />
      <Container>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: '#84abc1' }}>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>{t('id')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>{t('name')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>{t('type')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>{t('date')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>{t('status')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quotes.map((offer) => (
              <TableRow key={offer.id}>
                <TableCell>{offer.id}</TableCell>
                <TableCell>{offer.name}</TableCell>
                <TableCell>{offer.type}</TableCell>
                <TableCell>{offer.date}</TableCell>
                <TableCell>{offer.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
    </>
  );
};

export default QuoteList;