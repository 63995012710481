import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';


const MultilineBasicCreator = () => {
  const { t } = useTranslation();
  return (
    <div>
    <Typography variant="h4" gutterBottom>{t('multilineBasic')}</Typography>
    </div>
  );
};

export default MultilineBasicCreator;