import { useTranslation } from 'react-i18next'; 
import { Typography } from '@mui/material';

const MultislotCreator = () => {
  const { t } = useTranslation();
  return (
    <div>
    <Typography variant="h4" gutterBottom>{t('multislot')}</Typography>
    </div>
  );
};

export default MultislotCreator;