import React from 'react';
import { Box, Grid, Paper, Typography, Stack } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
const DashboardCard = ({ title, value, icon }) => (
  <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
    <Stack direction="row" spacing={2} alignItems="center">
      <Box display="flex" alignItems="center" justifyContent="center" minWidth={48}>
        {React.cloneElement(icon, { fontSize: "large" })}
      </Box>
      <Box>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h4">{value}</Typography>
      </Box>
    </Stack>
  </Paper>
);

function Dashboard() {
  const { t } = useTranslation();
  return (
    <Box>
      
      <Divider sx={{ width: '100%', mb: 4 }} />
      <Container>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard title={t('allOffers')} value="87" icon={<AssignmentIcon />} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard title={t('activeOffers')} value="42" icon={<CheckCircleIcon />} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard title={t('pendingOffers')} value="15" icon={<HourglassEmptyIcon />} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard title={t('acceptedOffers')} value="30" icon={<ThumbUpAltIcon />} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard title={t('averageOfferValue')} value="5 200 zł" icon={<AttachMoneyIcon />} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard title={t('highestOffer')} value="15 000 zł" icon={<TrendingUpIcon />} />
        </Grid>
      </Grid>
      </Container>
    </Box>
  );
}

export default Dashboard;