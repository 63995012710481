import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const ParkingCreator = () => {
    const { t } = useTranslation();
    
    return (
        <div>
        <Typography variant="h4" gutterBottom>{t('parkingowe')}</Typography>
        </div>
      );
};

export default ParkingCreator;